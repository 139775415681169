import { defineMessages, MessageDescriptor } from 'react-intl';

import { isWithinDateRange } from '.';

export type BannerConfigType = {
	longCopy: MessageDescriptor;
	url: string;
	ctaText: MessageDescriptor;
	textColor: string;
	backgroundColor: string;
	onClose: () => void;
	startDate: string;
	endDate: string;
	visiblePaths: string[];
	coupon?: string;
	queries?: Record<string, Array<{ key: string; value: string }[]>>;
	utm_source?: string;
};

export const messages = defineMessages({
	copyWMHD: {
		id: 'offer.banner.copyWMHD',
		defaultMessage: 'Get 50% off for World Mental Health Day',
		description: 'Description of offer',
	},
	copyCalmiversary: {
		id: 'offer.banner.copyCalmiversary',
		defaultMessage: "It's our birthday! Get 50% off",
		description: 'Description of offer',
	},
	ctaText: {
		id: 'offer.banner.CTA',
		defaultMessage: 'Get Offer Now',
		description: 'Description of offer',
	},
	copyPreBlackFriday: {
		id: 'offer.banner.copyPreBlackFriday',
		defaultMessage: 'Early Black Friday: Get 50% off Calm Premium before everyone else!',
		description: 'Description of offer',
	},
	copyBlackFriday: {
		id: 'offer.banner.copyBlackFriday',
		defaultMessage: 'Black Friday Sale: Get 50% off Calm Premium for a limited time!',
		description: 'Description of offer',
	},
	copyCyberMonday: {
		id: 'offer.banner.copyCyberMonday',
		defaultMessage: 'Cyber Monday Sale: Get 50% off Calm Premium for a limited time!',
		description: 'Description of offer',
	},
	copyDecPromo: {
		id: 'offer.banner.copyDecPromoNew',
		defaultMessage: 'Last chance to get 50% off Calm Premium',
		description: 'Description of offer',
	},
	copyDecGiftCardPromo: {
		id: 'offer.banner.copyDecGiftCardPromo',
		defaultMessage: 'Get 40% off annual gift cards - limited time only!',
		description: 'Description of offer',
	},
	copyJanPromo1: {
		id: 'offer.banner.copyJanPromo1',
		defaultMessage: "New Year's Special: Save 40% off Calm Premium!",
		description: 'Description of offer',
	},
	copyJanPromo2: {
		id: 'offer.banner.copyJanPromo2New',
		defaultMessage: "New Year's Sale: Get 50% Off Calm Premium",
		description: 'Description of offer',
	},
	copyFebPromo: {
		id: 'offer.banner.copyFebPromo',
		defaultMessage: 'Get 50% Off 1 Year of Calm Premium',
		description: 'Description of offer',
	},
	copygiftCardPromo30: {
		id: 'offer.banner.copygiftCardPromo30',
		defaultMessage: 'This weekend only: save 30% on gift cards',
		description: 'Description of offer',
	},
	copygiftCardPromo40: {
		id: 'offer.banner.copygiftCardPromo40',
		defaultMessage: 'This weekend only: save 40% on gift cards',
		description: 'Description of offer',
	},
});

export const configBase = {
	ctaText: messages.ctaText,
	textColor: '#fff',
	backgroundColor: '#1C2056',
	url: '/new-annual-offer',
	onClose: () => {},
};

const getHolidayPromoPathConfig = (promoConfig: Omit<BannerConfigType, 'visiblePaths'>) => {
	const PATH_CONFIG = {
		url: '/new-member-offer',
		visiblePaths: ['/', '/sleep', '/stress-anxiety', '/mindfulness'],
	};

	const UA_V2_PATH_CONFIG = {
		url: '/new-annual-offer',
		visiblePaths: ['/ua-homepage-v2'],
	};

	return [
		{
			...promoConfig,
			...PATH_CONFIG,
		},
		{
			...promoConfig,
			...UA_V2_PATH_CONFIG,
		},
	];
};

const getPreBlackFridayPromos = () => {
	const PROMO_CONFIG = {
		...configBase,
		longCopy: messages.copyPreBlackFriday,
		coupon: 'hol2024_Web_annual50_Banner',
		startDate: '11/03/2024',
		endDate: '11/11/2024',
	};

	return getHolidayPromoPathConfig(PROMO_CONFIG);
};

const getBlackFridayPromos = () => {
	const PROMO_CONFIG = {
		...configBase,
		longCopy: messages.copyBlackFriday,
		coupon: 'hol2024_Web_annual50_Banner',
		startDate: '11/20/2024',
		endDate: '12/01/2024',
	};

	return getHolidayPromoPathConfig(PROMO_CONFIG);
};

const getCyberMondayPromos = () => {
	const PROMO_CONFIG = {
		...configBase,
		longCopy: messages.copyCyberMonday,
		coupon: 'hol2024_Web_annual50_Banner',
		startDate: '12/02/2024',
		endDate: '12/03/2024',
	};

	return getHolidayPromoPathConfig(PROMO_CONFIG);
};

const DECEMBER_GIFT_CARD_PROMO = {
	...configBase,
	url: '/gift-card',
	visiblePaths: ['/ua-homepage-v2'],
	longCopy: messages.copyDecGiftCardPromo,
	coupon: 'holiday_annual_gift_2024',
	startDate: '12/04/2024',
	endDate: '12/14/2024',
};

const getDecemberPromos = () => {
	const PROMO_CONFIG = {
		...configBase,
		longCopy: messages.copyDecPromo,
		coupon: 'hol2024_Web_annual50_Banner',
		startDate: '12/15/2024',
		endDate: '12/20/2024',
	};

	return getHolidayPromoPathConfig(PROMO_CONFIG);
};

const JANUARY_ROUND_1_PROMO_CONFIG = {
	...configBase,
	longCopy: messages.copyJanPromo1,
	coupon: '40percent_welcome_offer',
	startDate: '01/01/2025',
	endDate: '01/18/2025',
	url: '/special-welcome-offer',
	visiblePaths: [
		'/',
		'/sleep',
		'/stress-anxiety',
		'/mindfulness',
		'/ua-homepage',
		'/ua-homepage-v2',
		'/signup-flow',
	],
	queries: {
		'/signup-flow': [
			[
				{
					key: 'focus',
					value: 'sleep_questionnaire',
				},
				{
					key: 'step',
					value: 'landing',
				},
			],
			[
				{
					key: 'focus',
					value: 'stress_questionnaire',
				},
				{
					key: 'step',
					value: 'landing',
				},
			],
		],
	},
};

const getJanuaryRound2Promos = () => {
	const PROMO_CONFIG = {
		...configBase,
		longCopy: messages.copyJanPromo2,
		coupon: 'Jan2025_UA_annual50_Misc',
		startDate: '01/19/2025',
		endDate: '01/31/2025',
	};

	return getHolidayPromoPathConfig(PROMO_CONFIG);
};

const FEBRUARY_PROMO_CONFIG = {
	...configBase,
	longCopy: messages.copyFebPromo,
	coupon: 'Jan2025_UA_annual50_Misc',
	startDate: '02/01/2025',
	endDate: '02/28/2025',
	url: '/new-annual-offer',
	visiblePaths: ['/ua-homepage-v2'],
};

const get30PercentGiftCardPromoConfig = (startDate: string, endDate: string) => {
	return {
		...configBase,
		url: '/gift-card',
		visiblePaths: ['/ua-homepage-v2'],
		longCopy: messages.copygiftCardPromo30,
		coupon: 'annualgift_30_2025',
		startDate,
		endDate,
	};
};

const GIFTCARD_PROMO_40_PERCENT_CONFIG = {
	...configBase,
	url: '/gift-card',
	visiblePaths: ['/ua-homepage-v2'],
	longCopy: messages.copygiftCardPromo40,
	coupon: 'annualgift_40_2025',
	startDate: '05/29/2025',
	endDate: '06/01/2025',
};

// This is a test config for unit tests - Do not remove
// TODO: Move this to mock test file
export const UNIT_TEST_CONFIG = {
	ctaText: messages.ctaText,
	textColor: '#fff',
	backgroundColor: '#1C2056',
	url: '/new-annual-offer',
	onClose: () => {},
	longCopy: messages.copyCalmiversary,
	coupon: 'wmhd2024_UA_annual50_Google',
	startDate: '10/01/2020',
	endDate: '10/16/2124',
	visiblePaths: ['/test-flow'],
};

export const bannerConfigs = [
	UNIT_TEST_CONFIG,
	...getPreBlackFridayPromos(),
	...getBlackFridayPromos(),
	...getCyberMondayPromos(),
	DECEMBER_GIFT_CARD_PROMO,
	...getDecemberPromos(),
	JANUARY_ROUND_1_PROMO_CONFIG,
	...getJanuaryRound2Promos(),
	FEBRUARY_PROMO_CONFIG,
	get30PercentGiftCardPromoConfig('04/14/2025', '04/30/2025'),
	get30PercentGiftCardPromoConfig('05/15/2025', '05/28/2025'),
	GIFTCARD_PROMO_40_PERCENT_CONFIG,
];

export function getActiveConfig(pathname: string): BannerConfigType {
	const filtered = bannerConfigs.filter(({ startDate, endDate, visiblePaths }) => {
		return isWithinDateRange(startDate, endDate) && visiblePaths.includes(pathname);
	});
	return filtered[0] || null;
}
