import { defineMessages } from 'react-intl';

export default defineMessages({
	monthlyAbbreviation: {
		id: 'paymentForm.month.abbreviation',
		defaultMessage: 'mo.',
		description: 'An abbreviation of the word month',
	},
	trialLabel: {
		id: 'paymentForm.year.trialLabel',
		defaultMessage: '14-Day Free Trial',
		description: 'A label for the sku that offers a 14 day trial',
	},
	customTrialLabel: {
		id: 'paymentForm.year.customTrialLabel',
		defaultMessage: '{amount}-Day Free Trial',
		description: 'A label for the sku that offers a custom day trial',
	},
	couponLabel: {
		id: 'paymentForm.year.couponLabel',
		defaultMessage: '{amount}% off',
		description: 'A label for the sku that offers a coupon',
	},
});
